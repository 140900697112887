import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import About from "../components/about";
import SEO from "../components/seo";

const AboutPage = ({ data }) => {
  const { site, markdownRemark } = data;
  return (
    <Layout>
      <SEO
        title={`About | ${site.siteMetadata.title}`}
        description={site.siteMetadata.description}
        image={site.siteMetadata.image}
      />
      <About html={markdownRemark.html} />
    </Layout>
  );
};

export default AboutPage;

export const aboutQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        description
        image
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
    }
  }
`;
