import React from "react";

export default function About({ html }) {
  return (
    <div className="about-header">
      <div
        className="primary-content"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
}
